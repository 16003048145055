import React, { useEffect, useState } from 'react';
import '../App.css'; // Import your CSS file
import Header from '../components/header';
import Footer from '../components/footer';

const DetailsPage = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const storedData = localStorage.getItem('uploadedFileData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            if (parsedData.structuredAiResponse) {
                setData(parsedData);
            } else {
                setError('No structured AI response found.');
            }
        } else {
            setError('No data available in local storage.');
        }
    }, []);

    if (error) {
        return <div>{error}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }

    const { structuredAiResponse } = data;

    return (
        <div className="details-container">
            <Header />
            <h1>Resume Analysis & Interview Prep</h1>
            <h2>Suggestions</h2>
            <div className="suggestion-cards">
                {structuredAiResponse.suggestions.map((suggestion, index) => (
                    <div key={index} className="suggestion-card">
                        <p className="before-text">
                            <i className="fas fa-arrow-left before-icon"></i> {/* Before icon */}
                            Before:
                        </p>
                        <p>{suggestion.before}</p>
                        <p className="after-text">
                            <i className="fas fa-arrow-right after-icon"></i> {/* After icon */}
                            After:
                        </p>
                        <p>{suggestion.after}</p>
                    </div>
                ))}
            </div>

            <h2>Questions</h2>
            <ul className="question-list">
                {structuredAiResponse.questions.map((question, index) => (
                    <li key={index} className="question-item">
                        <i className="fas fa-question-circle question-icon"></i> {/* Question icon */}
                        {question}
                    </li>
                ))}
            </ul>
            <Footer />
        </div>
    );
};

export default DetailsPage;
